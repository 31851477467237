:root {
  --black: #040404;
  --red: #CC0000;
  --pink: #FFDFDF;
  --green: #20B14C;
  --light-grey: #CCCCCC;
  --border-color: #E4E4E4;
  --teal: #C8E5E5;
  --light-teal: #E5EEF6;
  --white: #FFFFFF;
  --yellow: #f4b400;
  --transparent: rgba( 255, 255, 255, 0 );
  --modal-padding: 24px;
  --font-family: "Montserrat", sans-serif;
}
