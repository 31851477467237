/* You can add global styles to this file, and also import other style files */
@import "variables";
@import "functions";
@import 'styles/tasks.overrides';
@import 'styles/interactions.overrides';
@import 'styles/mat-form-field.overrides';
@import "@angular/material/prebuilt-themes/indigo-pink.css";

html, body { height: 100%; }
body { margin: 0; }
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-fill .mat-form-field-flex {
  background: var(--white);
}
body { margin: 0; font-family: var(--font-family); }

html{
  --mat-form-field-container-height: 50px;
  --mat-menu-item-label-text-size: 14px;
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-line-height: 15px;
  --mat-form-field-container-vertical-padding: 17px;
  --mdc-typography-font-family: montserrat;
  --mat-form-field-container-text-font: var(--font-family);
  --mdc-outlined-text-field-label-text-font: var(--font-family);
  --mdc-filled-text-field-label-text-font: var(--font-family);
  --mat-select-trigger-text-font: var(--font-family);
  --mdc-typography-font-family: var(--font-family);
  --mat-form-field-outlined-label-text-populated-size: 0.875rem;
  --mdc-outlined-text-field-label-text-size: 0.875rem;
  --mat-form-field-subscript-text-font: var(--font-family);
  --mdc-dialog-subhead-font: var(--font-family);
  --mat-tree-node-text-font: var(--font-family);
  --mat-table-header-headline-font: var(--font-family);
  --mat-table-row-item-label-text-font: var(--font-family);
  --mat-toolbar-title-text-font: var(--font-family);
  --mdc-filled-button-label-text-font: var(--font-family);
  --mdc-dialog-supporting-text-font: var(--font-family);
  --mat-select-enabled-arrow-color: var(--primary-color1);
  --mdc-outlined-text-field-label-text-tracking: 0;
  --mdc-dialog-supporting-text-line-height: 1.5;
  --mdc-checkbox-state-layer-size: 18px;
  --mdc-outlined-text-field-outline-color: var(--border-color);
}
.mat-typography {
  font-family: var(--font-family);
}


.mat-mdc-snack-bar-container {
  &.mat-toolbar__success .mdc-snackbar__surface {
    background: var(--green);
  }
  &.mat-toolbar__error .mdc-snackbar__surface {
    background: var(--red);
  }
  &.mat-toolbar__warning .mdc-snackbar__surface {
    background: var(--yellow);
  }

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: var(--white) !important;
  }

  .mat-mdc-simple-snack-bar span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mat-mdc-snack-bar-action {
    color: var(--white);
  }
}

.gradient {
  color: var(--white);
  background: linear-gradient(90deg, #35B5E5 0%, #2371B7 25%, #1B9793 50%, #1FB14B 75%, #7FBF41 100%);
  font-weight: bold;
}

.shadow {
  box-shadow: 0 _rem(3) _rem(6) #00000029;
}

.dashboard-shadow {
  border-radius: _rem(4);
  box-shadow: 0 _rem(10) _rem(20) rgba(0,0,0,0.19), 0 _rem(6) _rem(6) rgba(0,0,0,0.23)
}

// Define a mixin to generate margin and padding classes
@mixin generate-spacing-classes($max) {
  @for $i from 0 through $max {
      .m-#{$i} {
          margin: #{$i}px !important;
      }
      .mt-#{$i} {
          margin-top: #{$i}px !important;
      }
      .mr-#{$i} {
          margin-right: #{$i}px !important;
      }
      .mb-#{$i} {
          margin-bottom: #{$i}px !important;
      }
      .ml-#{$i} {
          margin-left: #{$i}px !important;
      }
      .pt-#{$i} {
          padding-top: #{$i}px !important;
      }
      .pr-#{$i} {
          padding-right: #{$i}px !important;
      }
      .pb-#{$i} {
          padding-bottom: #{$i}px !important;
      }
      .pl-#{$i} {
          padding-left: #{$i}px !important;
      }
  }
}

// Use the mixin to generate classes from margin and padding values
@include generate-spacing-classes(100);

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-select {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-value {
    font-family: var(--font-family);
  }
}

mat-form-field {
  .mat-mdc-text-field-wrapper{
    margin: 0.25em 0;
    .mat-mdc-form-field-flex{
      .mat-mdc-floating-label{
        top: 50%;
        &.mdc-floating-label--float-above{
          top: calc(50% - 0.15em);
        }
      }
    }
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none; // Initially hide the wrapper

    // Only show the subscript wrapper if it contains meaningful content
    &:not(:empty) {
      display: block; // Re-show if it has any content
    }

    &:has(.mat-mdc-form-field-hint-spacer:empty) {
      display: none; // Hide the parent when the child is empty
    }
  }
  &.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-outline {
    .mdc-notched-outline__notch{
      max-width: calc(100% - 30px) !important;
    }
  }
  &.textarea-field{
    .mat-mdc-text-field-wrapper{
      .mat-mdc-form-field-flex{
        .mat-mdc-floating-label{
          top: 25px;
          &.mdc-floating-label--float-above{
            top: calc(26% - 0.15em);
          }
        }
      }
    }
    
  }
  &:hover{
    &.textarea-field{
      .mat-mdc-text-field-wrapper{
        .mat-mdc-form-field-flex{
          .mat-mdc-floating-label{
            top: calc(var(--mat-form-field-container-height)/2);
          }
        }
      }
    }
  }
}

app-table-filter {
  mat-form-field {
    .mat-mdc-text-field-wrapper{
      margin: 0.25em 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    &:not(.mat-form-field-should-float) {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-label {
        margin-top: 0;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-underline {
      background: transparent;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-label {
       font-size: _rem(14);
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
mat-checkbox.mat-checkbox-checked {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-background {
    background-color: var(--primary-color2) !important;
  }
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background{
  background-color: #FFF !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.widget {
  background-color: var(--white);
  padding: _rem(18) _rem(18) _rem(30) _rem(18);
  border-radius: _rem(4);
  overflow: hidden;
  box-shadow: 0 _rem(3) _rem(6) #00000029;

  &--header {
    font-weight: bold;
    color: var(--secondary-color2);
  }

  &--item {
    padding: _rem(20) 0;

    .title {
      font-size: _rem(10);
      color: var(--secondary-color2);
      margin-bottom: _rem(4);
    }

    .value {
      color: var(--primary-color2);
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

i.pointer:hover,
span.pointer:hover {
  cursor: pointer;
}
.mdc-text-field{
  padding: 0 0.75em 0 0.75em !important;
}
.pb-0{
  padding-bottom: 0 !important;
}
.sm-font-container {
  font-size: _rem(12);
  --mdc-outlined-text-field-outline-color: var(--border-color);
  --mat-select-trigger-text-size: 12px;
  --mat-form-field-container-text-size: 12px;
  --mdc-outlined-text-field-label-text-size: 12px;
  --mat-select-trigger-text-size: 12px;
  mat-form-field {
    line-height: _rem(14);
    padding-bottom: _rem(20) !important;
    &.pb-0{
      padding-bottom: 0 !important;
    }
  }
  

  .mat-mdc-text-field-wrapper {
    height: 2.813rem !important; 
    flex: initial;
    &:has(textarea){
      height: auto !important;
    }
    .mdc-text-field__input {
      font-size: 12px !important;
      font-family: var(--font-family);
    }
    .mat-mdc-form-field-flex{
      .mat-mdc-floating-label{
        top: 50%;
        &.mdc-floating-label--float-above{
          top: 25px;
        }
      }
    }
  }
  .mat-mdc-form-field-flex {
    align-items: center;
    height: 2.813rem !important;
    &:has(textarea){
      height: auto !important;
    }
  }

  .mdc-text-field--outlined:hover {
    --mdc-outlined-text-field-outline-width: 2px;
  }
  .it-grid-layout{
    div > mat-form-field{
      padding-bottom: 0 !important;
    }
  }
}


.color-container {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex{
      .mat-mdc-floating-label{
        top: 50%;
        &.mdc-floating-label--float-above{
          top: 25px;
        }
      }
    }
  }
}
.custom-css-container{
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex{
      .mat-mdc-floating-label{
        top: 25%;
        &.mdc-floating-label--float-above{
          top: 25px;
        }
      }
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-arrow {
  color: var(--primary-color1);
}

.action-icon {
  color: var(--primary-color1);
  position: absolute;
  left: - _rem(19);
}

.icon-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.col__item {
  padding: _rem(18) _rem(48) _rem(18) _rem(26);
  border-bottom: _rem(1) solid #B1B1B1;
}

app-table-filter {
  .filter-row {

    .search-wrap {
      width: _rem(30);

      .icon-search {
        color: var(--primary-color1);
        // position: absolute;
        // top: _rem(-22);
      }
    }

    cm-button {
      margin: .25em 0;
      button {
        border-radius: _rem(5);
      }
    }
  }
}


app-origination,
app-engagement {
  router-outlet[name="right-sidebar-outlet"] ~ * {
    width: _rem(356);
  }
}

@media (max-width: $tablet) {
  app-origination,
  app-engagement {
    router-outlet[name="right-sidebar-outlet"] ~ * {
      width: 100%;
      margin-left: 0;
      margin-top: _rem(20);
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.mat-accent {
--mdc-radio-selected-focus-icon-color: var(--primary-color1) !important;
--mdc-radio-selected-hover-icon-color: var(--primary-color1) !important;
--mdc-radio-selected-icon-color: var(--primary-color1) !important;
--mdc-radio-selected-pressed-icon-color: var(--primary-color1) !important;
--mat-radio-ripple-color: black;
--mat-radio-checked-ripple-color: var(--primary-color1) !important;
}

.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important;
  background-color: var(--primary-color1) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--primary-color1) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary-color1) !important;
}


app-add-interaction-to-conversation,
app-add-task-to-conversation,
app-add-sale-to-conversation {
  display: inline-block;

  .inner-action {
    display: inline-block;
  }

  .inner-fields {
    display: none;
  }

  .inner-action-button {
    margin-bottom: _rem(15);
    position: relative;
    .inner-action-icon {
      position: absolute;
      left: _rem(5);
    }
    .icon-cross {
      display: none;
    }
  }

  .active {
    &.inner-action {
      display: block;
      margin-right: 0;
    }

    .inner-action-button {
      width: 100%;
      .icon-cross {
        display: block;
        position: absolute;
        right: _rem(5);
        top: 0;
      }
    }
    .inner-fields {
      display: block;
    }
  }
}

.title-gradient {
  font-family: var(--font-family);
  font-size: _rem(18);
  padding: _rem(12) _rem(0) _rem(12) _rem(19);
  border-radius: _rem(4);
  margin-bottom: _rem(21);
}

.mat-ripple {
  font-family: var(--font-family);
}

app-members-household-modal {
  form {
    cm-button {
      button {
        height: 45px !important;
      }
    }
  }
}

#custom-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.32);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  z-index: 1000;
  display: none;
}

.mat-mdc-dialog-container:has(.minimized) {
  display: none;
}

.seprator {
  border: solid 0.95px #e0e0e0;
}

.mdc-dialog__surface {
  padding: var(--modal-padding);
  &:has(app-confirmation-dialog) {
    padding: 0px;
  }
  .mat-mdc-dialog-title{
    padding-bottom: 0;
  }
}
.mat-mdc-dialog-container .mat-mdc-dialog-title+.mat-mdc-dialog-content{
  margin: 0 -24px !important;
  padding: 24px !important;
  overflow: inherit;
}

.mat-mdc-checkbox {
  --mdc-checkbox-selected-icon-color: var(--primary-color1)!important;
  --mdc-checkbox-selected-focus-icon-color: var(--primary-color1)!important;
  --mdc-checkbox-selected-hover-icon-color: var(--primary-color1)!important;
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-color1)!important;
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary-color1) !important;
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary-color1) !important;
  --mdc-checkbox-selected-icon-color: var(--primary-color1) !important;
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary-color1) !important;
  --mdc-checkbox-unselected-icon-color: var(--primary-color1) !important;
  --mdc-checkbox-unselected-hover-icon-color: var(--primary-color1) !important;
  --mdc-checkbox-unselected-pressed-icon-color: var(--primary-color1) !important;
  --mdc-checkbox-unselected-focus-icon-color: var(--primary-color1) !important;
  label{
    padding-left: 10px !important;
  }
}

.mat-mdc-menu-item-text span:last-of-type {
  margin-left: 10px !important;
}

.pt-5px {
  padding-top: 5px;
}

.mdc-text-field--outlined:hover {
  --mdc-outlined-text-field-outline-width: 2px;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  width: 6px !important;
}

.mat-mdc-form-field-has-icon-prefix:not(.mat-focused) {
  .mat-mdc-floating-label.mdc-floating-label {
    padding-left: 10px;
    // max-width: calc(100% - 10px);
    box-sizing: border-box;

    &.mdc-floating-label--float-above {
      padding-left: 0;
    }
  }
}
.mat-mdc-header-cell {
  font-weight: 600 !important;
}
.mdc-data-table__cell{
  box-sizing: inherit !important;
}