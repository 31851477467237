app-conversation-details,
app-edit-conversation-details {
  mat-form-field {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-wrapper {
      padding-bottom: 0;
      margin: 0;
    }
  }
}

app-input-autocomplete.search-field {
  --mdc-outlined-text-field-outline-color: #fff;
  --mdc-outlined-text-field-focus-outline-color: #fff;
  --mdc-outlined-text-field-hover-outline-color: #fff;
  --mdc-outlined-text-field-label-text-size: 12px;
  --mat-mdc-form-field-label-transform: translateY(calc(calc(6.75px + 54px / 2)* -1)) scale(var(52px, 0.75));
  font-family: "Montserrat", sans-serif !important;
  .mat-mdc-text-field-wrapper {
    margin-top: 7px !important;
  }
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mdc-notched-outline__notch{border-left: 0 !important;}
      .mat-mdc-floating-label {
        top: calc(46px / 2);
      }
    }
    &:hover{
      .mat-mdc-form-field-flex {
        .mat-mdc-floating-label {
          top: calc(44px / 2);
        }
      }
    }
  }
  .mat-mdc-form-field-label-always-float .mat-mdc-text-field-wrapper {
    margin-top: 9px !important;
  }
}

app-table-filter {
  --mdc-outlined-text-field-outline-color: var(--border-color);
  --mdc-outlined-text-field-hover-outline-color: var(--black);
  --mdc-outlined-text-field-outline-width: 1px;
}