@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?28284327');
  src: url('../font/fontello.eot?28284327#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?28284327') format('woff2'),
       url('../font/fontello.woff?28284327') format('woff'),
       url('../font/fontello.ttf?28284327') format('truetype'),
       url('../font/fontello.svg?28284327#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?28284327#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-view-sale:before { content: '\e800'; } /* '' */
.icon-create-sale:before { content: '\e801'; } /* '' */
.icon-view-note:before { content: '\e802'; } /* '' */
.icon-view-conversation:before { content: '\e803'; } /* '' */
.icon-create-task:before { content: '\e804'; } /* '' */
.icon-create-note:before { content: '\e805'; } /* '' */
.icon-create-conversation:before { content: '\e806'; } /* '' */
.icon-view-task:before { content: '\e807'; } /* '' */
.icon-conversation:before { content: '\e808'; } /* '' */
.icon-conversation_link:before { content: '\e809'; } /* '' */
.icon-create_conversation:before { content: '\e80a'; } /* '' */
.icon-plus_circle:before { content: '\e80b'; } /* '' */
.icon-create_note:before { content: '\e80c'; } /* '' */
.icon-aos_los:before { content: '\e80d'; } /* '' */
.icon-create_task:before { content: '\e80e'; } /* '' */
.icon-personal_info:before { content: '\e80f'; } /* '' */
.icon-accounts:before { content: '\e810'; } /* '' */
.icon-file:before { content: '\e811'; } /* '' */
.icon-sale:before { content: '\e812'; } /* '' */
.icon-member_profile:before { content: '\e813'; } /* '' */
.icon-note:before { content: '\e814'; } /* '' */
.icon-product:before { content: '\e815'; } /* '' */
.icon-logout:before { content: '\e816'; } /* '' */
.icon-organization:before { content: '\e817'; } /* '' */
.icon-read:before { content: '\e818'; } /* '' */
.icon-edit:before { content: '\e819'; } /* '' */
.icon-group:before { content: '\e81a'; } /* '' */
.icon-home:before { content: '\e81b'; } /* '' */
.icon-interaction:before { content: '\e81c'; } /* '' */
.icon-interaction_white:before { content: '\e81d'; } /* '' */
.icon-list_numbered-outline:before { content: '\e81e'; } /* '' */
.icon-document:before { content: '\e81f'; } /* '' */
.icon-trash:before { content: '\e820'; } /* '' */
.icon-member:before { content: '\e821'; } /* '' */
.icon-minus:before { content: '\e822'; } /* '' */
.icon-new_member:before { content: '\e823'; } /* '' */
.icon-minus_circle:before { content: '\e824'; } /* '' */
.icon-cross:before { content: '\e825'; } /* '' */
.icon-notification:before { content: '\e826'; } /* '' */
.icon-create_prospect:before { content: '\e827'; } /* '' */
.icon-bag:before { content: '\e828'; } /* '' */
.icon-task:before { content: '\e829'; } /* '' */
.icon-search:before { content: '\e82a'; } /* '' */
.icon-attachment:before { content: '\e82b'; } /* '' */
.icon-view:before { content: '\e82c'; } /* '' */
.icon-workflow:before { content: '\e82d'; } /* '' */
.icon-arrow_back:before { content: '\e82e'; } /* '' */
.icon-sync:before { content: '\e82f'; } /* '' */
.icon-notes-1:before { content: '\e830'; } /* '' */
.icon-plus:before { content: '\e831'; } /* '' */
.icon-tasks:before { content: '\e832'; } /* '' */
.icon-contact:before { content: '\e833'; } /* '' */
.icon-email:before { content: '\e834'; } /* '' */
.icon-conversations:before { content: '\e835'; } /* '' */
.icon-envelope-small:before { content: '\e836'; } /* '' */
.icon-mark:before { content: '\e837'; } /* '' */
.icon-add_document:before { content: '\e838'; } /* '' */
.icon-arrow_down:before { content: '\e839'; } /* '' */
.icon-upload:before { content: '\e83a'; } /* '' */
.icon-heart-empty:before { content: '\e83b'; } /* '' */
.icon-document-loupe:before { content: '\e83c'; } /* '' */
.icon-dashboard:before { content: '\e83d'; } /* '' */
.icon-star:before { content: '\e83e'; } /* '' */
.icon-alert:before { content: '\e83f'; } /* '' */
.icon-add-folder:before { content: '\e840'; } /* '' */
.icon-folder:before { content: '\e841'; } /* '' */
.icon-opened-folder:before { content: '\e842'; } /* '' */
.icon-lock:before { content: '\e843'; } /* '' */
.icon-concern:before { content: '\e844'; } /* '' */
.icon-import:before { content: '\e845'; } /* '' */
.icon-failed:before { content: '\e846'; } /* '' */
.icon-success:before { content: '\e847'; } /* '' */
.icon-dialog:before { content: '\e848'; } /* '' */
.icon-arrow_up:before { content: '\e849'; } /* '' */
.icon-setup:before { content: '\e84a'; } /* '' */
.icon-heart:before { content: '\e84b'; } /* '' */
.icon-content_management:before { content: '\e84c'; } /* '' */
.icon-view-opportunity:before { content: '\e84e'; } /* '' */
.icon-create-opportunity:before { content: '\e84f'; } /* '' */
.icon-create-concern:before { content: '\e850'; } /* '' */
.icon-view-concern:before { content: '\e851'; } /* '' */
.icon-info-concern:before { content: '\e852'; } /* '' */
.icon-info-opportunity:before { content: '\e853'; } /* '' */
.icon-info-sale:before { content: '\e854'; } /* '' */
.icon-info-conversation:before { content: '\e855'; } /* '' */
.icon-info-note:before { content: '\e856'; } /* '' */
.icon-info-task:before { content: '\e857'; } /* '' */
.icon-account:before { content: '\e864'; } /* '' */
