@import "./responsive-mixins";

app-customer-tasks {
  app-table-filter {
    @media (max-width: 1400px) {
      .break:nth-of-type(2) {
        flex: 100%;
      }
    }
  }
}

app-member-tasks {
  app-table-filter {
    @media (min-width: $tablet + 1) and (max-width: 1720px) {
      .break:nth-of-type(2) {
        flex: 100%;
      }
    }

    @media (max-width: $tablet) {
      .break {
        &:nth-of-type(1),
        &:nth-of-type(3) {
          flex: 100%;
        }
      }
    }
  }
}
